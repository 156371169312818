<template>
<div>
  <div class="container-fluid wrapper-medium mt-3">
    <div class="row mb-3">
      <div class="col-12">
        <b-link class="btn btn-link btn-sm pl-0" to="/dashboard"><span class="icon icon-th-large mr-2 pl-0"></span>Dashboard</b-link>
        <h2 class="anim-load1 mt-2">Members management</h2>
      </div>
    </div>
  </div>

  <div class="container-fluid wrapper-medium">

    <div class="row mb-3 mt-3">
      <div class="col-12">
        <div class="card mb-3">
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-md-9">
                <h4>Invite new member</h4>
                <p>Invite new member to your organisation to allow them to login and send TAYC-R questionnaires.</p>
              </div>
              <div class="col-12 col-md-3">
                <b-link class="btn btn-warning btn-block" to="/members-management/invite">Invite new member</b-link>
              </div>
            </div>
          </div> 
        </div>
      </div>
    </div>

    <div class="row mb-5">
      <div class="col-12 col-md-6">
        <div class="card mb-3">
          <div class="card-body">
            <h4 class="mb-3">Active member accounts</h4>
            <ListViewer 
              :items="therapistsActive" 
              :perPage="20" 
              btnLabel="Edit" 
              display="lastName,firstName"
              urlSlug="/members-management/"
              urlTarget="id"
              sortBy="lastName"
              :showSearch="true"
              :addBtn="isOrgSuperAdmin"
              addBtnLabel="View Profiles"
              addUrlSlug="/admin-profile/"
              addUrlTargetProp="id"
            />
          </div> 
        </div>
      </div>

      <div class="col-12 col-md-6">
        <div class="card mb-3">
          <div class="card-body">
            <h4 class="mb-3"><i class="icon icon-clock mr-2 pl-0"></i>Pending member accounts</h4>
            <ListViewer
              :items="therapistsPending" 
              :perPage="20" 
              btnLabel="View" 
              display="lastName,firstName"
              urlSlug="/members-management/invites/"
              urlTarget="id"
              sortBy="lastName"
              :showSearch="true"
            />
          </div> 
        </div>
      </div>
    </div>
  </div>

  <Footer />
</div>

</template>

<script>
import ListViewer from '@/components/modules/ListViewer';

export default {
  name: 'MembersManager',
  components: {
    ListViewer
  },
  data () {
    return {
      therapistsPending: [],
        therapistsActive: [],
        isOrgSuperAdmin: false,
    }
  },
  computed: {
  },

  async created() {
    this.$store.state.appSection = 'appUser'
    await Promise.all([this.getTherapistsActive(), this.getTherapistsPending()]);
    this.$store.state.loadingProgress = false
    if (this.$store.state.user.roles.includes('OrgSuperAdmin')) {
        this.isOrgSuperAdmin = true;
    }
  },

  methods: {
    getTherapistsActive() {
      this.$store.state.loadingProgress = true
      return this.$http({
        method: 'get', 
        url: this.$api.TherapistAllGet,
        headers: {'Authorization': 'bearer ' + this.$store.state.jwt } 
      })
        .then((response) => {
          console.log(response)
          this.therapistsActive = response.data
        })
        .catch((error) => {
          console.error('Error', error)
          this.alertMsgShow("Something went wrong", false);
        })
    },

    getTherapistsPending() {
      this.$store.state.loadingProgress = true
      return this.$http({
        method: 'get', 
        url: this.$api.TherapistInvitesGet,
        headers: {'Authorization': 'bearer ' + this.$store.state.jwt } 
      })
        .then((response) => {
          console.log(response)
          this.therapistsPending = response.data

          // Invites have no id's so this will generate them (avoiding use of email in url strings)
          this.therapistsPending.forEach(invite => {
            invite.id = encodeURIComponent(invite.email)
          });
        })
        .catch((error) => {
          console.error('Error', error)
          this.alertMsgShow("Something went wrong", false);
        })
    },

  }
}
</script>
